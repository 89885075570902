.content-sobre {
  display: flex;
  justify-content: center;
  margin-left: 2rem;
  margin-right: 2rem;
  flex-direction: column;
  margin-top: 5rem;
  text-align: justify !important;
}

.sobre{
  margin-left: 10%;
  margin-right: 10%;
}

.title-sobre {
  align-self: center;
}

.funcionalidades {
  color: white;
}

.text-funcionalidades{
  align-content: center !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.funcionalidades-list{
 display: flex;
 flex-direction: column;
 width: 50%;
}


@media screen and (max-width: 768px) {

  .funcionalidades-list{
    display: flex;
    flex-direction: column;
    width: 100%;
   }

   .sobre{
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}