h1 {
  color: #BCC7D4;
  font-weight: bold;
  font-size: 32px;
  margin-bottom: 1.5rem;
}

h2 {
  font-size: 24px;
  color: #BCC7D4;
  text-align: center;
  margin-bottom: 1.5rem;
  font-weight: bolder;
}

p {
  text-indent: 3rem;
  text-align: justify;
  color: #BCC7D4;
  margin-bottom: 1.5rem;
}

.texts-Section-style1 {
  margin-left: 10%;
  margin-right: 10%;
  align-items: center;
  display: flex;
  flex-direction: row;
}
.texts-Section-style2 {
  margin-left: 10%;
  margin-right: 10%;
  align-items: center;
  display: flex;
  flex-direction: row;
}


.left-text {
  width: 50%;
}

.right-text {
  width: 50%;
}

.left-image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.right-image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}



@media screen and (max-width: 768px) {
  h1 {
    color: #BCC7D4 !important;
    font-weight: bold !important;
    font-size: 32px !important;
    margin-bottom: 1.5rem !important;
  }
  
  h2 {
    font-size: 18px !important;
    color: #BCC7D4 !important;
    text-align: center !important;
    margin-bottom: 1.5rem !important;
    font-weight: bolder !important;
  }
  
  p {
    text-indent: 1rem !important;
    text-align: justify !important;
    color: #BCC7D4 !important;
    margin-bottom: 1.5rem !important;
  }


 .texts-Section-style1 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
    align-items: center !important;
    display: flex !important;
    flex-direction: column !important;
  }
 .texts-Section-style2 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
    align-items: center !important;
    display: flex !important;
    margin-top: 2rem;
    flex-direction: column-reverse !important;
  }


  .left-text {
    width: 100% !important;
  }

  .right-text {
    width: 100% !important;
  }

  .left-image {
    width: 100% !important;
  }

  .right-image {
    width: 100% !important;
  }
}