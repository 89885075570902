.modal {
   display: flex;
   /* Ou block ou grid, conforme a estrutura desejada */
   justify-content: center;
   /* Para centralizar o modal horizontalmente */
   align-items: center;
   /* Para centralizar o modal verticalmente */
   align-content: center;
   position: fixed;
   z-index: 100;
   /* Para garantir que o modal fique no topo de outros elementos */
   left: 0;
   top: 0;
   width: 100%;
   height: 100%;
   background-color: rgba(0, 0, 0, 0.5);
   /* Fundo semitransparente para o modal */
}

.modal-content {
   background-color: #fefefe;
   padding: 20px;
   max-width: 500px;
   /* Para limitar o tamanho */
   text-align: center;
   /* Opcional: para centralizar o conteúdo */
   border-radius: 20px;
}

.modal-content-container {
   width: 85%;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-content: center;
   margin: auto;
   gap: 1rem;
}

.close {
   font-size: 24px;
   text-align: center;
   display: flex;
}


.texts-modal-h3{
   font-size: 18px;
   font-weight: bold;
   padding: 10px;
}
.texts-modal{
   color: black !important;
}

.closeButton {
   width: 45px;
   height: 45px;
   border-radius: 100%;
   background-color: lightgray;
   align-content: center;
   text-align: center;
   display: flex;
   justify-content: center;
   cursor: pointer;
}

.AppStoreModal {
   width: 15rem;
   margin: auto;
}

.modal-buttons-store{
   display: flex;
   flex-direction: row;
   justify-content: center;
   gap: 1rem;
}

.WatchfolioLogoModal {
   width: 40%;
   margin: auto;
}