.App {
  text-align: center;
  background-color: #020617;
}

body{
  background-color: #020617;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.custom-header {
  font-size: 3rem; /* text-3xl */
  font-weight: bold; /* font-bold */
  text-align: center; /* text-center */
  color: white; /* text-white */
  position: relative; /* relative */
  z-index: 20; /* z-20 */
}

@media (min-width: 768px) { /* Assume md: applies from 768px, adjust as necessary */
  .custom-header {
      font-size: 4.5rem; /* md:text-7xl */
  }
}

@media (min-width: 1024px) { /* Assume lg: applies from 1024px, adjust as necessary */
  .custom-header {
      font-size: 4rem; /* lg:text-6xl */
  }
}

.custom-full-size {
  width: 100%; /* w-full */
  height: 100%; /* h-full */
}

.custom-full-screen {
  width: 100%; /* w-full */
  position: absolute; /* absolute */
  top: 0; /* inset-0 */
  right: 0; /* inset-0 */
  bottom: 0; /* inset-0 */
  left: 0; /* inset-0 */
  height: 100vh; /* h-screen */
}

.custom-main-container {
  height: 40rem; /* h-[40rem] */
  position: relative; /* relative */
  width: 100%; /* w-full */
  background-color: black; /* bg-black */
  display: flex; /* flex */
  flex-direction: column; /* flex-col */
  align-items: center; /* items-center */
  justify-content: center; /* justify-center */
  overflow: hidden; /* overflow-hidden */
}
